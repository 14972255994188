import Rails from '@rails/ujs';
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "form" ]

  submit() {
    if (this.hasFormTarget) {
      Rails.fire(this.formTarget, 'submit')
    }
  }
}
