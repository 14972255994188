import { Controller } from "@hotwired/stimulus"
let debounce = require('lodash/debounce');

export default class extends Controller {
  static targets = [ "map", "lat", "lng", "locationLoader" ]

  initialize() {
    this.searchMapController = debounce(this.searchMapController, 500).bind(this)
  }

  connect() {
  }

  updateLocation(event) {
    this.locationLoaderTarget.classList.remove("is-hidden")
    this.searchMapController(event.target.value)
  }

  searchMapController(searchString) {
    this.mapController.search(searchString, (latitude, longitude) => {
      this.latTarget.value = latitude
      this.lngTarget.value = longitude
      this.locationLoaderTarget.classList.add("is-hidden")
    })
  }

  get mapController() {
    return this.application.getControllerForElementAndIdentifier(this.mapTarget, "map")
  }
}
