import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "option", "textField" ]

  connect() {
  }

  updateOptions(event) {
    const searchString = event.target.value
    const searchRegex = new RegExp(searchString,"i")
    var foundAtLeastOne = false

    for (var option of this.optionTargets) {
      if (option.textContent.match(searchRegex) && option.textContent != searchString) {
        option.classList.remove('is-hidden')
        if (!foundAtLeastOne) {
          this.activeOption = option
          foundAtLeastOne = true
        }
      } else {
        option.classList.add('is-hidden')
      }
    }

    if (foundAtLeastOne) {
      this.showOptionList()
    } else {
      this.hideOptionList()
    }
  }

  hideOptionList() {
    this.element.classList.remove('is-active')
  }

  hideOptionListWithDelay() {
    setTimeout( () => { this.hideOptionList() }, 200)
  }

  showOptionList() {
    this.element.classList.add('is-active')
  }

  selectOption(event) {
    event.preventDefault()
    this.updateTextField(event.target.textContent)
    this.textFieldTarget.focus()
  }

  updateTextField(newValue) {
    this.textFieldTarget.value = newValue
    this.hideOptionList()
    this.textFieldTarget.dispatchEvent(new Event('input'))
  }

  navigateOptions(event) {
    switch (event.key) {
      case 'Escape':
        this.hideOptionList()
        event.preventDefault()
        break;
      case 'Enter':
        this.updateTextField(this.activeOption.textContent)
        event.preventDefault()
        break
      case 'ArrowUp':
        {
          const item = this.sibling(false)
          if (item) this.activeOption = item
          event.preventDefault()
        }
        break
      case 'ArrowDown':
        {
          const item = this.sibling(true)
          if (item) this.activeOption = item
          event.preventDefault()
        }
        break
      default:
        break
    }
  }

  disconnect() {
    // removeEventListener
  }

  sibling(next) {
    const options = this.visibleOptions
    const index = options.indexOf(this.activeOption)
    const sibling = next ? options[index + 1] : options[index - 1]
    const wrapAround = next ? options[0] : options[options.length - 1]
    return sibling || wrapAround
  }

  set activeOption(option) {
    for (const el of this.optionTargets) {
      el.classList.remove('is-active')
    }
    option.classList.add('is-active')
  }

  get visibleOptions() {
    return this.optionTargets.filter(function (option) {
      return !option.classList.contains("is-hidden")
    })
  }

  get activeOption() {
    for (const option of this.optionTargets) {
      if (option.classList.contains("is-active")) {
        return option
      }
    }
  }
}
