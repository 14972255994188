import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.hide = () => this.element.classList.remove("is-active")
    document.addEventListener("click", this.hide)
  }

  toggle(event) {
    event.stopPropagation()
    event.preventDefault()
    this.element.classList.toggle("is-active")
  }

  disconnect() {
    this.element.classList.remove("is-active")
    document.removeEventListener("click", this.hide)
  }
}
