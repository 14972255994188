import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "field", "toggleForForm" ]

  toggleForm(event) {
    event.preventDefault()

    for (var field of this.toggleForFormTargets) {
      field.classList.toggle("is-hidden")
    }

    setTimeout( () => { this.fieldTarget.focus() }, 200)
  }
}
