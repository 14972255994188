import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "availability", "playerId" ]

  select(event) {
    this.availabilityTarget.value = event.currentTarget.dataset.availability
    this.playerIdTarget.value = event.currentTarget.dataset.playerId
  }
}
