import { Controller } from "@hotwired/stimulus"
import { Loader } from "@googlemaps/js-api-loader"

export default class extends Controller {
  static values = {
    apiKey: String,
  }

  connect() {
    this.loader = new Loader({
      apiKey: this.apiKeyValue,
      version: "weekly",
    });

    this.loader.load().then(async () => {
      const { Map } = await google.maps.importLibrary("maps");
      const { AdvancedMarkerElement } = await google.maps.importLibrary("marker");

      let lat = parseFloat(this.data.get('lat') || this.data.get('home-lat') || 50.8957132)
      let lon = parseFloat(this.data.get('lng') || this.data.get('home-lng') || 4.3341225)
      let location = { lat: lat, lng: lon }

      var map = new Map(this.element, { zoom: 14, center: location, mapTypeControl: false, controlSize: 20, mapId: "d0f53b2d138f597a" });
      this.map = map

      const priceTag = document.createElement("div");

      priceTag.className = "is-size-2 box px-1 py-0 has-background-white";
      priceTag.textContent = "🏟";

      this.marker = new AdvancedMarkerElement({map, position: location, content: priceTag});
    });
  }

  search(searchString, callback) {
    this.loader.load().then(async () => {
      const { Geocoder } = await google.maps.importLibrary("geocoding");
      const geocoder = new Geocoder();
      geocoder
        .geocode({address: searchString})
        .then((result) => {
          const { results } = result;

          this.map.setCenter(results[0].geometry.location)
          this.marker.position = results[0].geometry.location
          callback(results[0].geometry.location.lat(), results[0].geometry.location.lng())
        })
        .catch((e) => {
          callback(null, null)
        });
    })
  }

  disconnect() {
    this.map.destroy()
  }
}
