import { Controller } from "@hotwired/stimulus"
import { Datepicker } from "vanillajs-datepicker"
import nl from 'vanillajs-datepicker/locales/nl'

export default class extends Controller {
  static targets = [ "hiddenField" ]

  connect() {
    Object.assign(Datepicker.locales, nl);
    this.datepicker = new Datepicker(this.element, {
      language: 'nl',
      daysOfWeekHighlighted: [0, 6],
      format: "yyyy-mm-dd"
    })

    this.datepicker.setDate(this.data.get('date'))

    this.element.addEventListener("changeDate", (event) => {
      let newDateString = Datepicker.formatDate(event.detail.date, "yyyy-mm-dd")
      this.data.set("date", newDateString)
      if (this.hasHiddenFieldTarget) {
        this.hiddenFieldTarget.value = newDateString
      }
    })
  }

  disconnect() {
    this.datepicker.destroy()
  }
}
