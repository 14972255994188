import { Controller } from "@hotwired/stimulus"
import { Datepicker } from "vanillajs-datepicker"
let debounce = require('lodash/debounce');

export default class extends Controller {
  static targets = [ "dateTitle", "teamsTitle", "opponent", "home", "date", "datepicker", "hour", "locationField", "resetLocationFieldIfHome"]

  initialize() {
    this.searchMapController = debounce(this.searchMapController, 500).bind(this)
  }

  connect() {
  }

  updateDate(event) {
    this.dateTitleTarget.innerHTML = Datepicker.formatDate(event.detail.date, "DD d MM, yyyy", "nl")
  }

  updateTeams() {
    if (this.homeTarget.checked) {
      this.teamsTitleTarget.innerHTML = `${this.data.get('currentTeam')} - ${this.opponentTarget.value}`
    } else {
      this.teamsTitleTarget.innerHTML = `${this.opponentTarget.value} - ${this.data.get('currentTeam')}`
    }
  }

  updateLocationField() {
    if (this.homeTarget.checked) {
      this.locationFieldTarget.classList.add("is-hidden")
      this.resetLocationFieldIfHomeTargets.forEach(function (inputField) {
        inputField.value = null
      });

    } else {
      this.locationFieldTarget.classList.remove("is-hidden")
    }
  }

  searchMapController(searchString) {
    this.mapController.search(searchString, (latitude, longitude) => {
      this.latTarget.value = latitude
      this.lngTarget.value = longitude
      this.locationLoaderTarget.classList.add("is-hidden")
    })
  }

  prepareHiddenFields() {
    this.dateTarget.value = `${this.datepickerTarget.dataset.datepickerDate} ${this.hourTarget.value}`
  }
}
